import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
}

function IncreseIcon({
  width = 20,
  height = 20,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="10" fill="#C3FCBB" />
        <path
          d="M9.25 14.6001C9.25 15.0143 9.58579 15.3501 10 15.3501C10.4142 15.3501 10.75 15.0143 10.75 14.6001L9.25 14.6001ZM10.5303 4.46977C10.2374 4.17688 9.76256 4.17688 9.46967 4.46977L4.6967 9.24274C4.40381 9.53563 4.40381 10.0105 4.6967 10.3034C4.98959 10.5963 5.46447 10.5963 5.75736 10.3034L10 6.06076L14.2426 10.3034C14.5355 10.5963 15.0104 10.5963 15.3033 10.3034C15.5962 10.0105 15.5962 9.53563 15.3033 9.24274L10.5303 4.46977ZM10.75 14.6001L10.75 5.0001L9.25 5.0001L9.25 14.6001L10.75 14.6001Z"
          fill="#0D8800"
        />
      </svg>
    </SVGContainer>
  );
}

export default IncreseIcon;
