import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
    width?: number;
    height?: number;
    iconColor?: string;
}

function TimesheetIcon({
    width = 20,
    height = 20,
    iconColor,
    ...restProps
}: SvgProps) {
    const theme = useTheme();

    const color = iconColor || theme.palette.primary.main;
    return (
        <SVGContainer {...restProps}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                color={color}
                data-name="Layer 1"
                viewBox="0 0 24 24"
            >
                <path fill="currentColor" d="M9 24H1a1 1 0 010-2h8a1 1 0 010 2zm-2-4H1a1 1 0 010-2h6a1 1 0 010 2zm-2-4H1a1 1 0 010-2h4a1 1 0 010 2zm8 7.955a1 1 0 01-.089-2A10 10 0 102.041 11.09a1 1 0 01-1.992-.18A12 12 0 0124 12a11.934 11.934 0 01-10.91 11.951.917.917 0 01-.09.004zM12 6a1 1 0 00-1 1v5a1 1 0 00.293.707l3 3a1 1 0 001.414-1.414L13 11.586V7a1 1 0 00-1-1z"></path>
            </svg>
        </SVGContainer>
    );
}

export default TimesheetIcon;
