import { combineReducers, configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { baseApi } from "src/Redux/api/baseApi";
import authReducer from "./features/common/auth/authSlice";
import { AuthState } from "./features/common/auth/types/slice";
import { customNotificationCreator } from "./middlewares/notificationCreator";
import { rtkQueryMutationNotifier } from "./middlewares/rtkQueryMutationNotifier";
import sessionTimeout from "./middlewares/sessionTimeout";
import { createPersistConfig } from "./utility";
import Configs from "src/Config";
import { PersistPartial } from "redux-persist/es/persistReducer";

const isProduction = Configs.NODE_ENV === "production";

const authPersistConfig = createPersistConfig(
  "auth",
  autoMergeLevel2<AuthState>,
  ["token", "user", "isAuthenticated"]
);

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  [baseApi.reducerPath]: baseApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleWare = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      baseApi.middleware,
      rtkQueryMutationNotifier,
      customNotificationCreator,
      sessionTimeout
    );

    if (process.env.NEXT_PUBLIC_REDUX_LOGGER_ON === "ON") {
      defaultMiddleWare.push(logger);
    }

    return defaultMiddleWare;
  },
  devTools: !isProduction,
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
