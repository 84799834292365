import { RootState } from "src/Redux";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { authApiEndPoints } from "./authApi";
import type { AuthState } from "./types/slice";

const initialState: AuthState = {
  token: null,
  user: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: (state) => {
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
    },

    updateUser: (state, action) => {
      const obj = action.payload!;
      state.user = { ...state.user, ...obj };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApiEndPoints.login.matchFulfilled,
      (state, action) => {
        state.token = action.payload.response?.token;
        state.user = action.payload.response?.User;
        state.isAuthenticated = true;
      }
    );
  },
});

export const { logOut, updateUser } = authSlice.actions;

const selectAuth = (state: RootState) => state.auth;
export const selectLoggedIn = createSelector(
  selectAuth,
  (auth) => !!auth.token
);
export const selectUser = createSelector(selectAuth, (auth) => auth.user);
export const selectUserId = createSelector(selectUser, (user) => user?.id);
export const selectToken = createSelector(selectAuth, (auth) => auth.token);
export const selectRehydrated = createSelector(
  selectAuth,
  (auth) => auth._persist.rehydrated
);

export default authSlice.reducer;
