import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  MuiBox,
  MuiButton,
  MuiContainer,
  MuiTypography,
} from "src/Components/MUI";
import OopsImage from "../.././assets/Images/404NotFound.png";


const Demo = styled("div")(({ theme }) => ({
  textAlign: "center",
  width: "100%",
  overflow: "auto",

  '.NotFound':{
    width:'400px',
    height:'484.18px',
  }
}));

const NotFound: React.FC = () => {
  return (
    <Demo>
      <MuiBox className="content-container-notfound body">
        <MuiContainer maxWidth="md" sx={{ margin: "40px auto" }}>
          <img src={OopsImage} alt="404" className='NotFound'/>
          <MuiTypography variant="h2" gutterBottom className="Subheading">
            Page Not Found
          </MuiTypography>
          <MuiTypography gutterBottom className="description" color={"gray"}>
            Sorry, the page you are looking for doesn’t exist or has been
            removed. <br /> Keep exploring out site
          </MuiTypography>
          <MuiButton
            component={Link}
            to="/"
            variant="contained"
            color="primary"
            sx={{ padding: "7px 45px 7px 45px", marginTop:'2rem', fontSize: "14px" }}
          >
            Back to Home
          </MuiButton>
        </MuiContainer>
      </MuiBox>
    </Demo>
  );
};
export default NotFound;
