import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { RouteInterface, routes } from "./Routes/Auth";
import GlobalStyles from "@mui/material/GlobalStyles";
import createPrimaryTheme from "./theme";
import { ThemeProvider } from "@mui/material";
import "./App.scss";
import Styles from "./GlobalStyle";
import Loading from "./Components/Loading";
import AuthLayout from "./Layouts/AuthLayout/AuthLayout";
import DashboardLayout from "./Layouts/DashboardLayout/DashboardLayout";
import { AdminRoutes } from "./Routes/Admin";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { TherapistRoutes } from "./Routes/Therapist";
import { UserRole } from "./Redux/features/common/auth/types/slice";
import NotFound from "./Views/404/NotFound";
import { logOut } from "./Redux/features/common/auth/authSlice";
import { DirectorRoutes } from "./Routes/Director";
import { store } from "./Redux";


const sessionTimeout = 3 * 60 * 60 * 1000; // 3 hours in milliseconds

function App() {
  const dispatch = useDispatch();
  const theme = createPrimaryTheme("light");
  const { isAuthenticated, user } = useSelector((state: any) => state.auth);
  
  function StrictlyNotRequireAuth({}) {
    return (
      <Suspense fallback={<Loading />}>
        <AuthLayout />
      </Suspense>
    );
  }

  function RequireAuth({ role }: { role: UserRole }) {
    if (role === user.role) {
      return (
        <Suspense fallback={<Loading />}>
          <DashboardLayout />
        </Suspense>
      );
    }
    return <NotFound />;
  }

  function PrivateRoute({ element, ...rest }: any) {
    // Use the isAuthenticated state to conditionally render routes
    return isAuthenticated ? element : <Navigate to="/login" replace={true} />;
  }

  function PublicRoute({ element, ...rest }: any) {
    if (isAuthenticated) {
      // Check user.role and navigate accordingly
      switch (user?.role) {
        case "superadmin":
          return <Navigate to="/admin/caseload" replace={true} />;
        case "therapist":
        case "pta":
          return <Navigate to="/therapist/caseload" replace={true} />;

        // return <Navigate to="/pta/caseload" replace={true} />;

        case "director":
          return <Navigate to="/director/caseload" replace={true} />;
      }
    }

    // If not authenticated, render the original element
    return element;
  }

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const handleLogout = () => {
      // Dispatch a logout action to update the Redux state
      dispatch(logOut());
    };

    const resetSessionTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(handleLogout, sessionTimeout);
    };

    // Start the session timer when the user is authenticated
    if (isAuthenticated) {
      resetSessionTimer();
    }

    // Attach event listeners to reset the session timer on user activity
    window.addEventListener("mousemove", resetSessionTimer);
    window.addEventListener("keydown", resetSessionTimer);

    // Clean up event listeners on component unmount
    return () => {
      clearTimeout(timer);
      window.removeEventListener("mousemove", resetSessionTimer);
      window.removeEventListener("keydown", resetSessionTimer);
    };
  }, [dispatch, isAuthenticated]);

  return (
    <>
      {!!theme ? (
        <ThemeProvider theme={theme}>
          <GlobalStyles styles={Styles(theme)} />
          <Router>
            <Routes>
              <Route
                element={<PublicRoute element={<StrictlyNotRequireAuth />} />}
              >
                {routes.map((route: RouteInterface, idx) => {
                  const title = { title: route.name };
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      element={<route.component />}
                    />
                  ) : null;
                })}
              </Route>
              <Route
                element={
                  <PrivateRoute element={<RequireAuth role="superadmin" />} />
                }
              >
                {AdminRoutes.map((route: RouteInterface, idx) => {
                  const title = { title: route.name };
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      element={<route.component />}
                    />
                  ) : null;
                })}
              </Route>
              <Route
                element={
                  <PrivateRoute element={<RequireAuth role={(user && user.role === "therapist") ? "therapist" : "pta"}  />} />
                }
              >
                {TherapistRoutes.map((route: RouteInterface, idx) => {
                  const title = { title: route.name };
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      element={<route.component />}
                    />
                  ) : null;
                })}
              </Route>
              <Route
                element={
                  <PrivateRoute element={<RequireAuth role="director" />} />
                }
              >
                {DirectorRoutes.map((route: RouteInterface, idx) => {
                  const title = { title: route.name };
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      element={<route.component />}
                    />
                  ) : null;
                })}
              </Route>
            </Routes>
          </Router>
        </ThemeProvider>
      ) : (
        <div />
      )}
    </>
  );
}

export default App;
