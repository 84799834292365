import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
}

function DirectorIcon({
  width = 17,
  height = 21,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 17 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.7857 5.22222C12.7857 7.55409 10.8669 9.44444 8.5 9.44444C6.13307 9.44444 4.21429 7.55409 4.21429 5.22222C4.21429 2.89035 6.13307 1 8.5 1C10.8669 1 12.7857 2.89035 12.7857 5.22222Z"
          stroke="white"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 12.6111C4.35786 12.6111 1 15.9192 1 20H16C16 15.9192 12.6421 12.6111 8.5 12.6111Z"
          stroke="white"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SVGContainer>
  );
}

export default DirectorIcon;
