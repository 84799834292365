import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
    width?: number;
    height?: number;
    iconColor?: string;
}

function PlusIcon({
    width = 22,
    height = 22,
    iconColor,
    ...restProps
}: SvgProps) {
    const theme = useTheme();

    const color = iconColor || theme?.colors?.invertColor.color1;
    return (
        <SVGContainer {...restProps}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                color={color}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 12h16m-8-8v16"
                ></path>
            </svg>
        </SVGContainer>
    );
}

export default PlusIcon;
