const Styles = (theme) => {
    return ({
        'html': {
            fontSize: '16px',

            '@media(min-width: 1921px)': {
                fontSize: '20px',
            },

            '@media(max-width: 1349px)': {
                fontSize: '14px',
            },

            '@media(max-width: 767px)': {
                fontSize: '12px',
            }
        },

        'html,body': {
            padding: 0,
            margin: 0,
            boxSizing: 'border-box'
        },

        '*': {
            boxSizing: 'border-box',
            borderColor: theme.colors.color.color2
        },

        'body': {
            backgroundColor: theme.colors.color.color3,
            color: theme.colors.invertColor.color1,
            fontFamily: `'Lato', 'sans-serif'`,
            fontSize: '1rem'
        },


        '.color-primary': {
            '&, *': {
                color: theme.colors.palatte.primary
            }
        },

        '.no-mb-text': {
            '@media(max-width: 1159px)': {
                display: 'none'
            }
        }
    })
}

export default Styles;