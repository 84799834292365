import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
  className?: string;
}

function ExportIcon({
  width = 15,
  height = 16,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 9.10526C14 11.3158 12.5556 15 7.5 15C2.44444 15 1 11.3158 1 9.10526M7.50289 1.03684V11.3158M3.16667 5.42105L7.5 1L11.8333 5.42105"
          stroke="#0F0F0F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SVGContainer>
  );
}

export default ExportIcon;
