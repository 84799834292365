import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
}

function DownArrowIcon({
  width = 13,
  height = 8,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.text.primary;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 13 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L6.5 7L12 1"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SVGContainer>
  );
}

export default DownArrowIcon;
