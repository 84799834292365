import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
  className?:string;
}

function ToggleMenuIcon({
  width = 24,
  height = 24,
  iconColor,
  className,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps} className={className}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke={color}
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0" />

        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <g id="SVGRepo_iconCarrier">
          {" "}
          <path
            d="M4 7L7 7M20 7L11 7"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
          />{" "}
          <path
            d="M20 17H17M4 17L13 17"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
          />{" "}
          <path
            d="M4 12H7L20 12"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
          />{" "}
        </g>
      </svg>
    </SVGContainer>
  );
}

export default ToggleMenuIcon;
