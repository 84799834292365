import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
}

function MeetingIcon({
  width = 22,
  height = 22,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || "#fffff";
  return (
    <SVGContainer {...restProps}>
      <svg color={color} fill="currentColor" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
        width={width} height={height} viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
        <g>
          <path d="M126.759,447.898c-25.589,0-46.426-20.835-46.426-46.442V198.726h329.722v108.097c12.168,2.95,23.561,7.8,33.921,14.23
		L443.532,96.54c-0.03-15.381-12.507-27.833-27.888-27.833h-0.012V57.28c0-26.984-21.95-48.917-49.567-48.917
		c-26.962,0-48.907,21.932-48.907,48.917v11.426h-22.722V57.28c0-26.984-21.95-48.917-49.572-48.917
		c-26.958,0-48.908,21.932-48.908,48.917v11.426h-22.722V57.28c0-26.984-21.945-48.917-49.567-48.917
		c-26.962,0-48.894,21.932-48.894,48.917v11.426c-15.386,0-27.866,12.46-27.89,27.845l-0.473,304.905
		c0,44.315,36.048,80.356,80.348,80.356h156.123c-6.416-10.363-11.217-21.764-14.165-33.915H126.759z M351.06,57.279
		c0-8.267,6.721-15.004,15.646-15.004c8.27,0,14.986,6.737,14.986,15.004v57.592c0,8.268-6.717,14.986-14.986,14.986h-0.642
		c-8.283,0-15.004-6.719-15.004-14.986V57.279z M229.864,57.279c0-8.267,6.735-15.004,15.646-15.004
		c8.265,0,15.004,6.737,15.004,15.004v57.592c0,8.268-6.739,14.986-15.004,14.986h-0.66c-8.251,0-14.986-6.719-14.986-14.986V57.279
		z M108.681,57.279c0-8.267,6.717-15.004,15.632-15.004c8.283,0,14.986,6.737,14.986,15.004v57.592
		c0,8.268-6.703,14.986-14.986,14.986h-0.646c-8.27,0-14.986-6.719-14.986-14.986V57.279z"/>
          <path d="M382.401,337.295c-45.924,0-83.152,37.239-83.152,83.168c0,45.941,37.228,83.174,83.152,83.174
		c45.959,0,83.188-37.232,83.188-83.174C465.589,374.534,428.361,337.295,382.401,337.295z M414.761,440.492
		c-1.61,2.596-4.384,4.014-7.219,4.014c-1.517,0-3.06-0.404-4.447-1.266l-25.14-15.567c-2.513-1.546-4.029-4.269-4.029-7.21v-49.15
		c0-4.688,3.805-8.484,8.476-8.484c4.707,0,8.494,3.796,8.494,8.484v44.428l21.129,13.077
		C415.986,431.283,417.229,436.503,414.761,440.492z"/>
          <path d="M268.119,317.869v-0.525c20.725-7.26,30.843-21.239,30.843-39.126c0-23.052-19.949-41.971-55.965-41.971
		c-17.554,0-34.094,4.049-45.139,9.01c-4.067,1.827-6.193,6.358-4.982,10.649l3.16,11.203c0.696,2.467,2.411,4.521,4.714,5.646
		c2.303,1.126,4.977,1.214,7.352,0.25c7.393-3.001,16.908-5.657,26.079-5.657c16.602,0,24.884,7.518,24.884,17.613
		c0,14.253-16.857,19.177-30.071,19.435h-6.272c-4.974,0-9.006,4.032-9.006,9.006v10.22c0,4.974,4.032,9.006,9.006,9.006h7.062
		c17.355,0,33.939,7.527,33.939,24.108c0,12.691-10.364,22.544-30.829,22.544c-10.934,0-21.853-3.118-29.921-6.253
		c-2.379-0.925-5.04-0.795-7.319,0.354c-2.28,1.149-3.973,3.211-4.643,5.675l-3.385,12.452c-1.167,4.293,0.971,8.774,5.036,10.582
		c10.445,4.643,25.682,8.276,43.068,8.276c11.522,0,21.708-1.692,30.619-4.577c3.303-26.104,15.148-49.569,32.728-67.416
		C292.613,327.545,281.239,320.223,268.119,317.869z"/>
        </g>
      </svg>
    </SVGContainer>
  );
}

export default MeetingIcon;
