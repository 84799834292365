import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
  className?: string;
}

function SlotCalenderIcon({
  width = 15,
  height = 16,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.4 0.9H2.5V0.1C2.5 0.045 2.545 0 2.6 0H3.3C3.355 0 3.4 0.045 3.4 0.1V0.9H6.6V0.1C6.6 0.045 6.645 0 6.7 0H7.4C7.455 0 7.5 0.045 7.5 0.1V0.9H9.6C9.82125 0.9 10 1.07875 10 1.3V9.6C10 9.82125 9.82125 10 9.6 10H0.4C0.17875 10 0 9.82125 0 9.6V1.3C0 1.07875 0.17875 0.9 0.4 0.9ZM0.9 9.1H9.1V4.35H0.9V9.1ZM9.1 3.5V1.8H7.5V2.4C7.5 2.455 7.455 2.5 7.4 2.5H6.7C6.645 2.5 6.6 2.455 6.6 2.4V1.8H3.4V2.4C3.4 2.455 3.355 2.5 3.3 2.5H2.6C2.545 2.5 2.5 2.455 2.5 2.4V1.8H0.9V3.5H9.1Z"
          fill="black"
          fillOpacity="0.69"
        />
      </svg>
    </SVGContainer>
  );
}

export default SlotCalenderIcon;
