import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
}

function PhoneIcon({
  width = 18,
  height = 18,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_867_368)">
          <path
            d="M16.6408 16.6245L17.3233 15.837C18.2308 14.9295 18.2308 13.4595 17.3233 12.552C17.3008 12.5295 15.4933 11.142 15.4933 11.142C14.5933 10.287 13.1758 10.287 12.2833 11.142L11.1883 12.012C8.70578 10.917 6.99578 9.21453 5.97578 6.81453L6.85328 5.71953C7.71578 4.82703 7.71578 3.40203 6.85328 2.50953C6.85328 2.50953 5.46578 0.702027 5.44328 0.679527C4.53578 -0.227973 3.06578 -0.227973 2.19578 0.642027L1.33328 1.39203C0.470781 2.25453 -0.00171867 3.41703 -0.00171873 4.67703C-0.00171898 10.407 7.59578 17.997 13.3183 17.997C14.5708 17.997 15.7408 17.5245 16.6333 16.6245L16.6408 16.6245ZM1.50578 4.67703C1.50578 3.82203 1.82078 3.03453 2.36828 2.49453L3.23078 1.74453C3.53828 1.43703 4.04078 1.42203 4.36328 1.71453C4.36328 1.71453 5.74328 3.50703 5.76578 3.52953C6.07328 3.83703 6.07328 4.37703 5.76578 4.69203C5.74328 4.71453 4.53578 6.22203 4.53578 6.22203C4.37078 6.43203 4.32578 6.70953 4.42328 6.95703C5.60828 10.062 7.82828 12.2895 11.0308 13.572C11.2783 13.6695 11.5633 13.632 11.7808 13.4595C11.7808 13.4595 13.2958 12.252 13.3108 12.237C13.6333 11.9145 14.1508 11.9145 14.4733 12.237C14.4958 12.2595 16.2883 13.6395 16.2883 13.6395C16.5808 13.962 16.5733 14.4645 16.2283 14.8095L15.5458 15.597C14.9683 16.1745 14.1808 16.497 13.3258 16.497C8.10578 16.497 1.50578 9.16953 1.50578 4.67703Z"
            fill="#999999"
          />
        </g>
        <defs>
          <clipPath id="clip0_867_368">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(18 18) rotate(180)"
            />
          </clipPath>
        </defs>
      </svg>
    </SVGContainer>
  );
}

export default PhoneIcon;
