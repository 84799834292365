import React, { useState } from "react";
import { StyledSidebar } from "../styled";
import { Link, NavLink } from "react-router-dom";
import { LogoIcon, AngleIcon } from "../../Components/Icons";
import {
  MuiBox,
  MuiListItem,
  MuiTooltip,
} from "../../Components/MUI";
import { useSelector } from "react-redux";
import { menuItems } from "./Menu";
import Logo from "src/Components/Icons/Logo";
import type { RoleModel } from "src/Models/Roles.models";
import { classNames } from "src/utils/helpers";
interface SidebarProps {
  sidebarExpanded?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ sidebarExpanded }) => {
  const [showChild, setShowChild] = useState<string>('');
  const { user } = useSelector((state: any) => state.auth);


  const menu = menuItems[user.role as RoleModel];
  const url =
    (user.role as RoleModel) === "superadmin"
      ? "admin"
      : (user.role as RoleModel) === "pta"
        ? "therapist"
        : user.role;

  return (
    <StyledSidebar>
      <Link className="logo-part" to={`/${url}${menu[0].pathname}`}>
        {!sidebarExpanded ? <LogoIcon width={73} /> : <Logo />}
      </Link>

      <MuiBox className="side-items-sec">
        {menu.map((item, index) => (
          <MuiBox className="sideBar-items" key={index}>
            <MuiListItem>
              <NavLink
                onClick={() => {
                  if (!!item.children) {
                    setShowChild(prev => {
                      if (prev === item.title.replaceAll(" ", "_")) {
                        return '';
                      }
                      return item.title.replaceAll(" ", "_");
                    });
                  } else {
                    setShowChild('');
                  }
                }}
                to={!item.children ? `/${url}${item.pathname}` : '#'}
                style={{ display: 'block', width: '155%' }}
                className={(navData) => classNames('item-btn', { active: !!item.pathname && !!navData.isActive, link: !navData.isActive })}
              >
                <MuiTooltip title={item.title} placement="right" arrow disableHoverListener={sidebarExpanded}>
                  <MuiBox className="item-btn justify-content-between">
                    <MuiBox className="d-flex align-items-center">
                      <MuiBox className="btn-icon">
                        <item.icon/>
                      </MuiBox>
                      <MuiBox className="btn-txt">{item.title}</MuiBox>
                    </MuiBox>


                    {!!item.children?.length && <MuiBox
                      className=" with-children angle-rotate"
                      style={{
                        transform: showChild === item.title.replaceAll(" ", "_") ? 'rotateY(180deg) rotate(180deg)' : 'rotateY(0deg) rotate(0deg)',
                        transition: 'transform 0.5s ease-out',
                        transformOrigin: 'center', // Set the transform origin to the center
                        
                      }}
                    >
                      <AngleIcon />
                    </MuiBox>}
                  </MuiBox>
                </MuiTooltip>
                <MuiBox className={classNames("child-menu", { show: showChild === item.title.replaceAll(" ", "_") })}>
                  {item.children?.map((subItem, index) => <React.Fragment key={index}>
                    <MuiListItem>
                      <NavLink
                        to={`/${url}${subItem.pathname}`}
                        style={{ display: 'block', width: '100%' }}
                        className={(navData) => classNames('item-btn', { active: !!navData.isActive, link: !navData.isActive })}
                        onClick={() => {
                          // Close the parent menu if it's open
                          setShowChild('');
                        }}
                      >
                        <MuiTooltip title={subItem.title} placement="right" arrow disableHoverListener={sidebarExpanded}>
                          <MuiBox className="item-btn">
                            <MuiBox className="btn-icon">
                              <subItem.icon />
                            </MuiBox>
                            <MuiBox className="btn-txt">{subItem.title}</MuiBox>
                          </MuiBox>
                        </MuiTooltip>
                      </NavLink>
                    </MuiListItem>
                  </React.Fragment>)}
                </MuiBox>
              </NavLink>
            </MuiListItem>
          </MuiBox>
        ))}
      </MuiBox>
    </StyledSidebar>
  );
};

export default Sidebar;
