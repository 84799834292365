import type { PersistConfig, StateReconciler } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import Configs from 'src/Config';

function createPersistConfig<S>(key: string, reconciler: StateReconciler<S>, whitelist?: string[]): PersistConfig<S> {
   let config = {
     key,
     version: 1,
     storage,
     stateReconciler: reconciler,
     transforms: [
       encryptTransform({
         secretKey: Configs.PUBLIC_REDUX_PERSIST_ENC_SECRET!,
       }),
     ],
   };

   if (whitelist && whitelist.length) {
      Object.assign(config, {
         whitelist,
      });
   }

   return config;
}

export { createPersistConfig };
