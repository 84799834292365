import { styled } from "@mui/material";
import React from "react";
import { MuiTypography } from "./MUI";

type FallbackProps ={
  subtitle:string
}

const StyledContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 12.5rem;
`;

const Fallback: React.FC<FallbackProps> = ({subtitle}) => {
  return (
    <StyledContainer>
      <img
        src="/loading.gif"
        alt="Loading"
        style={{ width: 100, height: 100, marginTop: 16 }}
      />
      <MuiTypography
        gutterBottom
        variant="subtitle1"
        sx={{ mb: 2, textAlign: "center" }}
        color="gray"
      >
        {subtitle}
      </MuiTypography>
    </StyledContainer>
  );
};

export default Fallback;
