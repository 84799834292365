import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
  className?: string;
}

function ImportIcon({
  width = 24,
  height = 24,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.31995 11.6799L11.8799 14.2399L14.4399 11.6799"
          stroke="#0F0F0F"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.88 4V14.17"
          stroke="#0F0F0F"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 12.1799C20 16.5999 17 20.1799 12 20.1799C7 20.1799 4 16.5999 4 12.1799"
          stroke="#0F0F0F"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SVGContainer>
  );
}

export default ImportIcon;
