import { baseApi } from "src/Redux/api/baseApi";
// import type { CommonResponseBody } from 'src/store/types/api';
import type {
  ChangePasswordArg,
  ChangeProfilePictureArg,
  ForgetPasswordArg,
  LoginQueryArg,
  ResetPasswordArg,
  UpdateProfileArg,
} from "./types/api";

const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // FIXME: give a return type
    login: builder.mutation<any, LoginQueryArg>({
      query: ({ body }) => ({
        url: `auth/login`,
        method: "POST",
        body,
      }),
    }),
    changePassword: builder.mutation<any, ChangePasswordArg>({
      query: (body) => ({
        url: `auth/change-password`,
        method: "PATCH",
        body: body,
      }),
    }),
    forgetPassword: builder.mutation<any, ForgetPasswordArg>({
      query: (body) => ({
        url: `auth/forget-password`,
        method: "POST",
        body: body,
      }),
    }),

    

    resetPassword: builder.mutation<any, ResetPasswordArg>({
      query: (body) => ({
        url: `auth/reset-password`,
        method: "POST",
        body: body,
      }),
    }),

    resendPassword: builder.mutation<any, number | null>({
      query: (id) => ({
        url: `auth/reset-system-password/${id}`,
        method: "POST",
      }),
    }),

    changeProfilePicture: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: `auth/change-profile-photo`,
        method: "POST",
        body: formData,
      }),
    }),

    updateUserProfile: builder.mutation<any, { body: UpdateProfileArg }>({
      query: ({ body }) => ({
        url: `auth/edit-profile`,
        method: "PATCH",
        body,
      }),
    }),

    getUserDetails: builder.query<any, any>({
      query: () => {
        return `auth/view-profile`;
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyGetUserDetailsQuery,
  useChangePasswordMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useChangeProfilePictureMutation,
  useUpdateUserProfileMutation,
  useResendPasswordMutation,
  endpoints: authApiEndPoints,
} = authApi;
