// sessionMiddleware.ts

import { Middleware } from '@reduxjs/toolkit';
import { logOut } from '../features/common/auth/authSlice';

const sessionMiddleware: Middleware = ({ dispatch }) => next => action => {
  if (action.error && action.payload.status === 401) {
    dispatch(logOut());
  }
  return next(action);
};

export default sessionMiddleware;

