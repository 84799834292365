import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
  className?:string;
}

function EditIcon({
  width = 12,
  height = 15,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.2838 3.82673C11.5673 3.53743 11.7233 3.15322 11.7233 2.74453C11.7233 2.33583 11.5673 1.95163 11.2838 1.66233L10.0942 0.448492C9.81075 0.159192 9.43425 0 9.03375 0C8.63325 0 8.25675 0.159192 7.974 0.447727L0 8.55962V11.9386H3.30975L11.2838 3.82673ZM9.03375 1.53069L10.224 2.74376L9.0315 3.95607L7.842 2.743L9.03375 1.53069ZM1.5 10.4079V9.19486L6.78 3.82366L7.9695 5.0375L2.69025 10.4079H1.5ZM0 13.4693H12V15H0V13.4693Z"
          fill="#3B3D3F"
        />
      </svg>
    </SVGContainer>
  );
}

export default EditIcon;
