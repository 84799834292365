import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
  className?: string;
}

function ListIcon({
  width = 18,
  height = 10,
  iconColor,
  className,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M1 6C1.55 6 2 5.55 2 5C2 4.45 1.55 4 1 4C0.45 4 0 4.45 0 5C0 5.55 0.45 6 1 6ZM1 10C1.55 10 2 9.55 2 9C2 8.45 1.55 8 1 8C0.45 8 0 8.45 0 9C0 9.55 0.45 10 1 10ZM1 2C1.55 2 2 1.55 2 1C2 0.45 1.55 0 1 0C0.45 0 0 0.45 0 1C0 1.55 0.45 2 1 2ZM5 6H17C17.55 6 18 5.55 18 5C18 4.45 17.55 4 17 4H5C4.45 4 4 4.45 4 5C4 5.55 4.45 6 5 6ZM5 10H17C17.55 10 18 9.55 18 9C18 8.45 17.55 8 17 8H5C4.45 8 4 8.45 4 9C4 9.55 4.45 10 5 10ZM4 1C4 1.55 4.45 2 5 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H5C4.45 0 4 0.45 4 1ZM1 6C1.55 6 2 5.55 2 5C2 4.45 1.55 4 1 4C0.45 4 0 4.45 0 5C0 5.55 0.45 6 1 6ZM1 10C1.55 10 2 9.55 2 9C2 8.45 1.55 8 1 8C0.45 8 0 8.45 0 9C0 9.55 0.45 10 1 10ZM1 2C1.55 2 2 1.55 2 1C2 0.45 1.55 0 1 0C0.45 0 0 0.45 0 1C0 1.55 0.45 2 1 2ZM5 6H17C17.55 6 18 5.55 18 5C18 4.45 17.55 4 17 4H5C4.45 4 4 4.45 4 5C4 5.55 4.45 6 5 6ZM5 10H17C17.55 10 18 9.55 18 9C18 8.45 17.55 8 17 8H5C4.45 8 4 8.45 4 9C4 9.55 4.45 10 5 10ZM4 1C4 1.55 4.45 2 5 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H5C4.45 0 4 0.45 4 1Z"
          fill="#434343"
        />
      </svg>
    </SVGContainer>
  );
}

export default ListIcon;
