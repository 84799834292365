import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
  className?: string;
}

function FileExchangeIcon({
  width = 24,
  height = 24,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps}>
      <svg width={width} height={height} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" strokeWidth="3" stroke="#2e2e2e" fill="none"><polygon points="27.4 11.53 14.8 11.53 14.8 37.25 34.73 37.25 34.73 19.35 27.4 11.53" strokeLinecap="round"/><polyline points="27.12 11.53 27.13 19.71 34.73 19.71"/><polyline points="29.66 38.25 29.66 52.47 49.6 52.47 49.6 34.57 42.26 26.75 34.75 26.75" strokeLinecap="round"/><polyline points="41.98 26.75 42 34.93 49.6 34.93"/><polyline points="46.8 21.68 46.8 12.31 38.56 12.31"/><polyline points="50.83 18.48 46.67 22.42 42.88 18.48"/><polyline points="17.2 42.35 17.2 52.47 25.44 52.47"/><polyline points="13.17 46.3 17.33 42.35 21.12 46.3"/></svg>
    </SVGContainer>
  );
}

export default FileExchangeIcon;
