import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
  className?: string;
}

function PenIcon({
  width = 27,
  height = 27,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 27 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="26" height="26" rx="13" fill="#F6F8F9" />
        <rect x="0.5" y="0.5" width="26" height="26" rx="13" stroke="#B0BABF" />
        <g clipPath="url(#clip0_108_1971)">
          <path
            d="M19.3787 7.62181C19.0105 7.25412 18.5114 7.04761 17.991 7.04761C17.4706 7.04761 16.9715 7.25412 16.6032 7.62181L7.79355 16.4315C7.54127 16.6823 7.34124 16.9807 7.20503 17.3094C7.06882 17.6381 6.99913 17.9905 7.00001 18.3463V19.4583C7.00001 19.602 7.05708 19.7397 7.15866 19.8413C7.26024 19.9429 7.39802 20 7.54167 20H8.65372C9.00946 20.001 9.36187 19.9314 9.69055 19.7953C10.0192 19.6592 10.3176 19.4592 10.5685 19.207L19.3787 10.3968C19.7462 10.0285 19.9526 9.52954 19.9526 9.00929C19.9526 8.48904 19.7462 7.99004 19.3787 7.62181ZM9.80259 18.4411C9.49709 18.7445 9.08432 18.9154 8.65372 18.9166H8.08334V18.3463C8.08279 18.1328 8.12459 17.9213 8.20632 17.7241C8.28805 17.5269 8.40808 17.3479 8.55947 17.1974L15.2453 10.5116L16.4911 11.7574L9.80259 18.4411ZM18.6123 9.63085L17.2548 10.9888L16.009 9.74568L17.367 8.38772C17.4488 8.3061 17.5458 8.24139 17.6527 8.19728C17.7595 8.15317 17.8739 8.13054 17.9895 8.13066C18.105 8.13079 18.2195 8.15367 18.3262 8.19801C18.4329 8.24235 18.5298 8.30728 18.6114 8.38908C18.6931 8.47088 18.7578 8.56796 18.8019 8.67477C18.846 8.78158 18.8686 8.89604 18.8685 9.0116C18.8684 9.12716 18.8455 9.24156 18.8012 9.34828C18.7568 9.455 18.6919 9.55193 18.6101 9.63356L18.6123 9.63085Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_108_1971">
            <rect
              width="13"
              height="13"
              fill="white"
              transform="translate(7 7)"
            />
          </clipPath>
        </defs>
      </svg>
    </SVGContainer>
  );
}

export default PenIcon;
