import loadable from "@loadable/component";
import { ComponentType } from "react";
import { Fallback } from '../Components';

const SignUpView =loadable(()=>import('../Views/Auth/SignUp.View'),{
    fallback:<Fallback subtitle="Loading data..."/>
})

const LoginView = loadable(() => import("../Views/Auth/Login.View"), {
    fallback: <Fallback subtitle="Loading data..."/>
});

const ForgetPasswordView = loadable(() => import("../Views/Auth/ForgetPassword.View"), {
    fallback: <Fallback subtitle="Loading data..."/>
});


export interface RouteInterface {
    path: string;
    exact?: boolean, 
    name:string;
    component:ComponentType ;
    
}


export const routes: Array<RouteInterface> = [
  { path: "/signup", exact: true, name: "Signup", component: SignUpView },
  { path: "/", exact: true, name: "Login", component: LoginView },
  { path: "/login", exact: true, name: "Login", component: LoginView },
  {
    path: "/forget-password",
    exact: true,
    name: "Forget Password",
    component: ForgetPasswordView,
  },
];