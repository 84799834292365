export default class Configs {
  public static NODE_ENV: string = process.env.NODE_ENV || "development";
  public static API_PROTOCOL: string = process.env.REACT_APP_API_PROTOCOL || "http";
  public static API_HOSTNAME: string = process.env.REACT_APP_API_HOSTNAME || "localhost";
  public static API_PORT: string = process.env.REACT_APP_API_PORT || "3080";
  // public static API_PROTOCOL: string = "https";
  // public static API_HOSTNAME: string = "therasync.intellexio.com";
  // public static API_PORT: string = "443";
  public static PUBLIC_API_HOST: string = `${this.API_PROTOCOL}://${this.API_HOSTNAME}:${this.API_PORT}/${this.NODE_ENV === "production" ? "api" : ""}`;
  public static PUBLIC_REDUX_PERSIST_ENC_SECRET: string =
    "58703273357638792F423F4528472B4B6250655368566D597133743677397A24";
  public static PUBLIC_SPREADSHEET_LICENCE_KEY: string =
    "MDU0YWIxODZlZmY2NWNhNzcyN2M1YjAwMWY5ZDRhN2Y5ODBmZjViNTEzNDQxMWY0MjRhMDE5OTA4YjAyYmE4ODYyNzAwZmY2YjA3MzBjNjM0MTQ4NzU0M2VlNWRkMzE0ODVmZGEzMTM2NjJiOWU3YmMxZTE1MTM1MzQwZTI3OGUsZXlKdVlXMWxJam9pVTJGb2FXd2lMQ0prWVhSbElqb3hOamszTmpjd01EQXdMQ0prYjIxaGFXNGlPbHNpY0hKdlptOXlZMlV1YVc1MFpXeHNaWGhwYnk1amIyMGlMQ0ozWldJaUxDSnNiMk5oYkdodmMzUWlYU3dpY0d4aGJpSTZNQ3dpYzJOdmNHVWlPbHNpZGpjaUxDSjJPQ0lzSW5ZNUlpd2lkakV3SWwxOQ==";
  public static encryptKey: string = 'u7fB3w9Xk7TzL2Yx5eRwQ8aZ1dHsV6MgNpQcJ4lR0sDmXnBf'


  // secret details

  public static REGION: string = "us-east-1";
  public static SECRET_NAME: string = "therasync_secret"
}

