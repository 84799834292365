import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
}

function TherapistIcon({
  width = 17,
  height = 20,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 17 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.33333 10C11.0908 10 13.3333 7.7575 13.3333 5C13.3333 2.2425 11.0908 0 8.33333 0C5.57583 0 3.33333 2.2425 3.33333 5C3.33333 7.7575 5.57583 10 8.33333 10ZM8.33333 1.66667C10.1717 1.66667 11.6667 3.16167 11.6667 5C11.6667 6.83833 10.1717 8.33333 8.33333 8.33333C6.495 8.33333 5 6.83833 5 5C5 3.16167 6.495 1.66667 8.33333 1.66667ZM16.6667 19.1667C16.6667 19.6275 16.2942 20 15.8333 20C15.3725 20 15 19.6275 15 19.1667C15 16.845 13.6358 14.835 11.6667 13.8967V15.9767C12.6367 16.3208 13.3333 17.2467 13.3333 18.3333V19.1667C13.3333 19.6275 12.9608 20 12.5 20C12.0392 20 11.6667 19.6275 11.6667 19.1667V18.3333C11.6667 17.8733 11.2933 17.5 10.8333 17.5C10.3733 17.5 10 17.8733 10 18.3333V19.1667C10 19.6275 9.6275 20 9.16667 20C8.70583 20 8.33333 19.6275 8.33333 19.1667V18.3333C8.33333 17.2467 9.03 16.3208 10 15.9767V13.3933C9.7275 13.3542 9.44917 13.3333 9.16667 13.3333H7.5C6.92083 13.3333 6.36167 13.4183 5.83333 13.5767V16.0575C6.33167 16.3458 6.66667 16.8842 6.66667 17.5008C6.66667 18.4217 5.92083 19.1675 5 19.1675C4.07917 19.1675 3.33333 18.4217 3.33333 17.5008C3.33333 16.8842 3.66833 16.3458 4.16667 16.0575V14.3833C2.65667 15.4392 1.66667 17.19 1.66667 19.1675C1.66667 19.6283 1.29417 20.0008 0.833333 20.0008C0.3725 20.0008 0 19.6283 0 19.1675C0 15.0317 3.36417 11.6675 7.5 11.6675H9.16667C13.3025 11.6675 16.6667 15.0308 16.6667 19.1667Z"
          fill="white"
        />
      </svg>
    </SVGContainer>
  );
}

export default TherapistIcon;
