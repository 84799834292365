import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
  className?:string;
}

function SearchIcon({
  width = 18,
  height = 18,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.87446 1.5C6.8579 1.50009 5.85611 1.74327 4.95265 2.20927C4.04919 2.67527 3.27028 3.35056 2.68088 4.17882C2.09149 5.00707 1.70872 5.96426 1.56449 6.97053C1.42026 7.97681 1.51877 9.00298 1.85179 9.96344C2.18481 10.9239 2.74269 11.7908 3.47889 12.4918C4.21508 13.1928 5.10824 13.7076 6.08384 13.9932C7.05945 14.2788 8.08921 14.327 9.08722 14.1337C10.0852 13.9404 11.0225 13.5112 11.821 12.882L14.56 15.621C14.7014 15.7576 14.8909 15.8332 15.0875 15.8315C15.2842 15.8298 15.4723 15.7509 15.6113 15.6119C15.7504 15.4728 15.8293 15.2847 15.831 15.0881C15.8327 14.8914 15.7571 14.702 15.6205 14.5605L12.8815 11.8215C13.6225 10.8815 14.0838 9.7518 14.2128 8.56179C14.3417 7.37179 14.1331 6.16952 13.6106 5.09257C13.0882 4.01562 12.2731 3.10752 11.2587 2.47217C10.2442 1.83683 9.07143 1.49992 7.87446 1.5ZM2.99946 7.875C2.99946 6.58207 3.51307 5.34209 4.42731 4.42785C5.34155 3.51361 6.58153 3 7.87446 3C9.16739 3 10.4074 3.51361 11.3216 4.42785C12.2358 5.34209 12.7495 6.58207 12.7495 7.875C12.7495 9.16793 12.2358 10.4079 11.3216 11.3221C10.4074 12.2364 9.16739 12.75 7.87446 12.75C6.58153 12.75 5.34155 12.2364 4.42731 11.3221C3.51307 10.4079 2.99946 9.16793 2.99946 7.875Z"
          fill="black"
          fillOpacity="0.49"
        />
      </svg>
    </SVGContainer>
  );
}

export default SearchIcon;
