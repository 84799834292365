import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
  className?: string;
}

function EyeOpenIcon({ width = 18, height = 18, iconColor, ...restProps }:SvgProps) {
  const theme = useTheme();

  const color = iconColor || '#C1C1C6';
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.8657 8.38575C17.2072 6.94575 14.6249 2.25 8.9999 2.25C3.3749 2.25 0.792653 6.94575 0.134153 8.38575C0.0457571 8.57873 0 8.78849 0 9.00075C0 9.21301 0.0457571 9.42277 0.134153 9.61575C0.792653 11.0542 3.3749 15.75 8.9999 15.75C14.6249 15.75 17.2072 11.0542 17.8657 9.61425C17.9539 9.42149 17.9995 9.21199 17.9995 9C17.9995 8.78801 17.9539 8.57851 17.8657 8.38575ZM8.9999 14.25C4.26965 14.25 2.0624 10.2255 1.4999 9.00825C2.0624 7.7745 4.26965 3.75 8.9999 3.75C13.7189 3.75 15.9269 7.75725 16.4999 9C15.9269 10.2428 13.7189 14.25 8.9999 14.25Z"
          fill={color}
        />
        <path
          d="M9 5.25C8.25832 5.25 7.5333 5.46993 6.91661 5.88199C6.29993 6.29404 5.81928 6.87971 5.53545 7.56494C5.25162 8.25016 5.17736 9.00416 5.32206 9.73159C5.46675 10.459 5.8239 11.1272 6.34835 11.6517C6.8728 12.1761 7.54098 12.5333 8.26841 12.6779C8.99584 12.8226 9.74984 12.7484 10.4351 12.4645C11.1203 12.1807 11.706 11.7001 12.118 11.0834C12.5301 10.4667 12.75 9.74168 12.75 9C12.7488 8.0058 12.3533 7.05267 11.6503 6.34966C10.9473 5.64666 9.9942 5.25119 9 5.25ZM9 11.25C8.55499 11.25 8.11998 11.118 7.74997 10.8708C7.37996 10.6236 7.09157 10.2722 6.92127 9.86104C6.75098 9.4499 6.70642 8.9975 6.79323 8.56105C6.88005 8.12459 7.09434 7.72368 7.40901 7.40901C7.72368 7.09434 8.12459 6.88005 8.56105 6.79323C8.99751 6.70642 9.44991 6.75097 9.86104 6.92127C10.2722 7.09157 10.6236 7.37996 10.8708 7.74997C11.118 8.11998 11.25 8.55499 11.25 9C11.25 9.59674 11.0129 10.169 10.591 10.591C10.169 11.0129 9.59674 11.25 9 11.25Z"
          fill={color}
        />
      </svg>
    </SVGContainer>
  );
}

export default EyeOpenIcon;
