import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
  className?:string;
}

function CloseIcon({
  width = 18,
  height = 17,
  iconColor,
  className,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || "#6F7782";
  return (
    <SVGContainer {...restProps} className={className}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="1"
          y1="-1"
          x2="20.9638"
          y2="-1"
          transform="matrix(0.728024 0.685551 -0.728024 0.685551 0.779297 1.72278)"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          x1="1"
          y1="-1"
          x2="20.963"
          y2="-1"
          transform="matrix(-0.727605 0.685996 -0.727605 -0.685996 16.7598 0.780518)"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </SVGContainer>
  );
}

export default CloseIcon;
