import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
}

function InvoiceSettingIcon({
  width = 23,
  height = 19,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || '#fff';
  return (
    <SVGContainer {...restProps}>
      <svg width={width} height={height} viewBox="0 0 1024 1024" className="icon" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M731.44 548.57L548.58 649.14v201.14l182.86 100.57L914.3 850.29V649.14L731.44 548.57z m109.72 258.47l-109.71 60.34-109.71-60.34V692.39l109.71-60.34 109.71 60.34v114.65z" fill="currentColor" /><path d="M676.58 749.71c0 30.3 24.56 54.86 54.86 54.86s54.86-24.56 54.86-54.86-24.56-54.86-54.86-54.86-54.86 24.57-54.86 54.86z m73.15 0c0 10.08-8.2 18.29-18.29 18.29s-18.29-8.2-18.29-18.29 8.2-18.29 18.29-18.29 18.29 8.21 18.29 18.29zM219.52 475.38h219.43v73.14H219.52z" fill="currentColor" /><path d="M182.62 366.27h585.62v142.91h73.14V145.62c0-39.96-32.5-72.48-72.46-72.48h-27.36c-29.2 0-55.05 16.73-65.88 42.59-5.71 13.61-27.84 13.64-33.55 0-10.86-25.88-36.71-42.59-65.89-42.59h-18.16c-29.2 0-55.05 16.73-65.87 42.59-5.71 13.61-27.84 13.64-33.55 0-10.86-25.88-36.71-42.59-65.89-42.59h-17.43c-29.2 0-55.05 16.73-65.88 42.59-5.71 13.61-27.84 13.64-33.55 0-10.87-25.87-36.73-42.59-65.91-42.59h-27.57c-39.96 0-72.48 32.52-72.48 72.48v805.12h401.21V877.6h-328.1l-0.44-511.33zM208.43 144c14.27 34.07 47.32 56.09 84.23 56.09 36.89 0 69.95-22 82.66-53.8l15.86-2.29c14.27 34.07 47.32 56.09 84.23 56.09 36.89 0 69.95-22 82.66-53.8l16.59-2.29c14.27 34.07 47.32 56.09 84.23 56.09 36.89 0 69.95-22 82.66-53.8l26.68-0.66v147.5H182.56l-0.12-146.84 25.99-2.29z" fill="currentColor" /></svg>
    </SVGContainer>
  );
}

export default InvoiceSettingIcon;
