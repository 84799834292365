import { useEffect, useState } from "react";
import { MuiMenuItem, MuiTextField } from ".";
import { TextFieldProps, MenuList, Backdrop } from "@mui/material";
import { styled } from "@mui/material";

// Define the Student type
type Student = {
  id: number;
  name: string;
  firstName: string;
  middleName: string;
  lastName: string;
  address: string;
  district: string;
  pincode: string | number;
  city: string;
  state: string;
  gender: string;
};

const StyledWrapper = styled("div")(({ theme }) => ({
  position: "relative",

  ".list-part": {
    position: "absolute",
    top: "calc(100% + 0.5rem)",
    left: "0",
    boxShadow: "0 0 5px #ccc",
    borderRadius: "0.5rem",
    width: "100%",
    backgroundColor: "#fff",
    zIndex: theme.zIndex.appBar,
  },
}));

// Make the component generic
const MuiSearchTextField = <T extends Record<string, any>>(
  props: TextFieldProps & {
    data: Array<T>;
    nameKey: keyof T; // The key in T to use for display
    selectValue: (value: T) => void;
  }
) => {
  const { data, nameKey, type, autoComplete, selectValue, ...rest } = props;
  const [isSearchListShown, setSearchListShown] = useState<boolean>(false);

  useEffect(() => {
    if (data.length > 0) {
      setSearchListShown(true);
      return;
    }
    setSearchListShown(false);
  }, [data]);

  return (
    <>
      {isSearchListShown && (
        <Backdrop
          onClick={() => setSearchListShown(false)}
          className="transparent"
          open={true}
        />
      )}
      <StyledWrapper>
        <MuiTextField
          type={type || "search"}
          autoComplete={autoComplete || "false"}
          {...rest}
        />
        {isSearchListShown && (
          <div className="list-part">
            <MenuList>
              {data.map((item, index) => (
                <MuiMenuItem
                  key={index}
                  onClick={() => {
                    selectValue(item);
                    setSearchListShown(false);
                  }}
                >
                  {`${item["firstName"]} ${item["middleName"] || ""} ${item["lastName"]}`.trim()}
                </MuiMenuItem>
              ))}
            </MenuList>
          </div>
        )}
      </StyledWrapper>
    </>
  );
};

export default MuiSearchTextField;
