import { styled } from "@mui/material";
import { MuiBadge, MuiCard, MuiMenu } from "../Components/MUI";

// -----------------Auth Layout Style--------------------

export const AuthStyle = styled("div")(({ theme }) => ({
  backgroundColor: theme.colors.invertColor.color2,
}));

export const AuthContainer = styled(MuiCard)(({ theme }) => ({
  width: "56.94rem",
  height: "auto",
  padding: "0 4rem",
}));

// ----------------DashBoardLayout Style ---------------------
export const StyleDashboardLayout = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "100vh",
  display: "flex",

  '.welcome': {
    display: 'flex',
    alignItems: 'center',

    '@media(max-width: 767px)': {
      display: 'none'
    }
  },

  '.logo-part': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '4.2rem'
  },

  '.side-menu-parent': {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: theme.zIndex.drawer
  },

  '&:not(.sidebar-expanded)': {
    '.side-menu-parent': {
      '&, .side-items-sec': {
        width: '5rem',

        '@media(max-width: 767px)': {
          left: '-15rem'
        }
      },

      '.side-items-sec': {
        '.btn-icon': {
          justifyContent: 'center',

          '&.with-children': {
            display: 'none'
          }
        },

        '.btn-txt': {
          display: 'none'
        }
      }
    }
  },

  '&.sidebar-expanded': {
    '.side-menu-parent': {
      '&, .side-items-sec': {
        width: '15rem',

        '@media(max-width: 767px)': {
          width: '15rem',
        }
      }
    },

    '.child-menu:not(.show)': {
      display: 'none',
    }
  },

  "&, .dashboard-container": {
    width: "100%",
    height: "100vh",
    display: "flex",
  },

  ".full-view": {
    width: "100%",
  },


}));

export const StyleDashboardBody = styled("div")(({ theme }) => ({
  paddingTop: " 1rem",
  paddingBottom: "1rem",
  overflow: "auto",
  paddingLeft: '6rem',
  paddingTop: '5rem',

  '.sidebar-expanded &': {
    paddingLeft: '16rem',
  },

  '@media(max-width: 767px)': {
    paddingLeft: '1rem !important',
  },

  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

// -----------------Header Section Style --------------------
export const StyledHeader = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: '5rem',
  height: "4rem",
  width: "calc(100% - 5rem)",
  color: theme.colors.color.color1,
  borderBottom: "0.01rem solid #0000002b",
  backgroundColor: theme.colors.invertColor.color2,
  zIndex: theme.zIndex.appBar,

  '.sidebar-expanded &': {
    left: '15rem',
    width: "calc(100% - 15rem)",
  },

  '@media(max-width: 767px)': {
    left: '0 !important',
    width: "100% !important",
  },

  ".container": {
    width: "100%",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "inherit",
    paddingRight: "2.3rem",
  },

  ".header-items": {
    position: "relative",
    width: "100%",
    display: "flex",
  },
  ".header-items": {
    ".active": {
      ".btn-txt": {
        color: "#FF9F33",
      },
    },
  },
  a: {
    textDecoration: "none",
  },

  "div.item-btn": {
    position: "relative",
    width: "100%",
    height: "3.12rem",
    // marginTop: "0.3rem",
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    borderRadius: "7px",
    // filter: "grayscale(1) brightness(0.7)",
    transition: "0.3s",

    "&:hover": {
      filter: "grayscale(0) brightness(1)",
    },
  },
  ".header-items a.active div.item-btn": {
    filter: "grayscale(0) brightness(1)",
  },
  ".btn-txt": {
    position: "relative",
    // width: "calc(100% - 3.12rem)",
    textDecoration: "none",
    color: theme.colors.color.color1,
  },

  "&, .container": {
    display: "flex",
    alignItems: "center",
  },

  ".container": {
    justifyContent: "space-between",
    height: "inherit",
    paddingRight: "2.3rem",
  },
  ".res-sidebar-toggle": {
    position: "relative",
    // display: "none",
    cursor: "pointer",
  },
  "@media(max-width: 1020px)": {
    ".container": {
      justifyContent: "flex-start",
      paddingRight: "1rem",
    },
    ".user-container": {
      marginLeft: "auto",
      "& svg": {
        width: "28px",
        height: "28px",
      },
    },
    ".res-sidebar-toggle": {
      display: "block",
      marginRight: "10px",
      marginTop: "4px",
    },
  },
  "@media(max-width:767px)": {
    "div.item-btn": {
      height: "auto",
      marginTop: "0",
    },
  },
}));
export const StyledBadge = styled(MuiBadge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "red",
  },
}));

export const StyledMenu = styled(MuiMenu)((theme) => ({
  " .btn-txt": {
    position: "relative",
    width: "100%",
    fontSize: "1.06rem",
    textDecoration: "none",
  },
  a: {
    textDecoration: "none",
    color: "#000000",
  },
}));

// --------------------------- Sidebar Styles-------------------------

export const StyledSidebar = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  backgroundColor: theme.colors.invertColor.color2,

  "&, .angle-rotate:hover":{
    color:"inherit",
  },

  "&, .side-container": {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  ".side-logo": {
    width: "100%",
    height: "4rem",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.colors.invertColor.color2,
    borderRight: `0.01rem solid ${theme.colors.invertColor.color3}`,
    padding: "0 2.06rem",
    paddingTop: "2rem",

    "@media(min-width: 1024px)": {
      justifyContent: "center",
    },
  },

  ".side-items-sec": {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // padding: "0 2.06rem",
    paddingTop: "1.5rem",
    height: " calc(100% - 5rem)",
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  ".menu-text": {
    position: "relative",
    // fontSize: "0.93rem",
    fontWeight: "600",
    color: theme.colors.color.color1,
    margin: "0",
    textTransform: "uppercase",
    paddingLeft: "1.56rem",
    opacity: "0.5",
  },
  ".sideBar-items": {
    position: "relative",
    width: "100%",
    // paddingTop: "0.93rem",
    display: "flex",
    flexDirection: "column",
  },
  ".menuShort-items": {
    alignItems: "center",
  },

  a: {
    textDecoration: "none",
  },
  "div.item-btn": {
    position: "relative",
    width: "100%",
    height: "2.5rem",
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    borderRadius: "7px",
    filter: "grayscale(1) brightness(0.7)",
    transition: "0.3s",

    "&:hover": {
      filter: "grayscale(0) brightness(1)",
    },
  },
  ".sideBar-items a.active div.item-btn": {
    filter: "grayscale(0) brightness(1)",
    backgroundColor: "rgba(233, 233, 233, 0.11)", // Light black background
    borderRadius: "7px", // Border radius
    ".btn-icon svg": {},
  },
  ".btn-icon": {
    position: "relative",
    width: "2.5rem",
    height: "100%",
    display: "flex",
    alignItems: "center",

    'svg': {
      color: `${theme.colors.color.color1}`
    }
  },
  ".menuShort-btn": {
    position: "relative",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  ".btn-txt": {
    position: "relative",
    width: "100%",
    fontSize: "1rem",
    textDecoration: "none",
    color: theme.colors.color.color1,
  },
  ".side-bottom-sec": {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0 2.06rem",
    paddingTop: "2.18rem",
    marginTop: "auto",
  },
  ".side-bottom-sec a": {
    position: "relative",
    fontSize: "1rem",
    width: "100%",
    display: "none",
    justifyContent: "flex-start !important",
  },
  ".side-bottom-sec button": {
    position: "relative",
    fontSize: "1rem",
    width: "100%",
    display: "none",
    justifyContent: "flex-start !important",
    marginBottom: "0.43rem",
  },

  "@media(max-width: 1024px)": {
    ".side-bottom-sec a": {
      display: "flex",
    },
    ".side-bottom-sec button": {
      display: "flex",
    },
  },
}));
