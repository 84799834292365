import { Component } from "react";
import { CircularProgress, Theme } from "@mui/material";
// import { Props } from '../Redux/Models';

class Loading extends Component {
  public render() {
    const { classes }: any = this.props;
    return (
      <CircularProgress
        className={classes.circularProgress}
        size={90}
        thickness={1}
        color="secondary"
      />
    );
  }
}

export default Loading;
