import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { toast, type TypeOptions } from "react-toastify";

export type NotificationActionCreatorPayload = {
  type: TypeOptions;
  message: string;
};

export const NotificationActionCreatorType =
  "therasync-app/custom-notification";

export const notificationActionCreator = (
  payload: NotificationActionCreatorPayload
) => ({
  type: NotificationActionCreatorType,
  payload,
});

export const customNotificationCreator: Middleware =
  (_api: MiddlewareAPI) => (next: Dispatch<any>) => (action) => {
    if (action.type !== NotificationActionCreatorType) {
      return next(action);
    }

    toast(action.payload.message, {
      type: action.payload.type,
    });
  };
