import React, { useEffect, useState } from "react";
import { StyleDashboardBody, StyleDashboardLayout } from "../styled";
import { Outlet } from "react-router-dom";
import { MuiBox } from "../../Components/MUI";
import Header from "./Header";
import { Props } from "../../utils/constant";
import Sidebar from "./Sidebar";
import { classNames } from "src/utils/helpers";

const DashboardLayout: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const toggleMenu = () => {
    setSidebarExpanded((prev) => !prev);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 899) {
        setSidebarExpanded(false);
      } else {
        setSidebarExpanded(true);
      }
    };

    handleResize(); // Call it initially

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <StyleDashboardLayout className={classNames('dashboard-layout', { 'sidebar-expanded': sidebarExpanded })}>
        <MuiBox className="dashboard-container">
          <div className="sidebar-bg-overlay" onClick={toggleMenu}></div>
          <div className="side-menu-parent">
            <Sidebar sidebarExpanded={sidebarExpanded} />
          </div>

          <MuiBox className="full-view">
            <Header
              handleMenu={handleMenu}
              anchorEl={anchorEl}
              handleClose={handleClose}
              toggleMenu={toggleMenu}
              sidebarExpanded={sidebarExpanded}
            />
            <StyleDashboardBody className={classNames("container", { 'sidebar-shown': sidebarExpanded })}>
              <Outlet />
            </StyleDashboardBody>
          </MuiBox>
        </MuiBox>
      </StyleDashboardLayout>
    </>
  );
};

export default DashboardLayout;
