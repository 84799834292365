import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
  className?: string;
}

function SOAPIcon({
  width = 18,
  height = 18,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || "#C1C1C6";
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.9375 6.75C5.9375 6.60082 5.99676 6.45774 6.10225 6.35225C6.20774 6.24676 6.35082 6.1875 6.5 6.1875H11C11.1492 6.1875 11.2923 6.24676 11.3977 6.35225C11.5032 6.45774 11.5625 6.60082 11.5625 6.75C11.5625 6.89918 11.5032 7.04226 11.3977 7.14775C11.2923 7.25324 11.1492 7.3125 11 7.3125H6.5C6.35082 7.3125 6.20774 7.25324 6.10225 7.14775C5.99676 7.04226 5.9375 6.89918 5.9375 6.75ZM6.5 9.5625H11C11.1492 9.5625 11.2923 9.50324 11.3977 9.39775C11.5032 9.29226 11.5625 9.14918 11.5625 9C11.5625 8.85082 11.5032 8.70774 11.3977 8.60225C11.2923 8.49676 11.1492 8.4375 11 8.4375H6.5C6.35082 8.4375 6.20774 8.49676 6.10225 8.60225C5.99676 8.70774 5.9375 8.85082 5.9375 9C5.9375 9.14918 5.99676 9.29226 6.10225 9.39775C6.20774 9.50324 6.35082 9.5625 6.5 9.5625ZM8.75 10.6875H6.5C6.35082 10.6875 6.20774 10.7468 6.10225 10.8523C5.99676 10.9577 5.9375 11.1008 5.9375 11.25C5.9375 11.3992 5.99676 11.5423 6.10225 11.6477C6.20774 11.7532 6.35082 11.8125 6.5 11.8125H8.75C8.89918 11.8125 9.04226 11.7532 9.14775 11.6477C9.25324 11.5423 9.3125 11.3992 9.3125 11.25C9.3125 11.1008 9.25324 10.9577 9.14775 10.8523C9.04226 10.7468 8.89918 10.6875 8.75 10.6875ZM15.5 3.375V11.0173C15.5005 11.1651 15.4716 11.3115 15.415 11.448C15.3583 11.5845 15.2752 11.7084 15.1702 11.8125L11.5625 15.4202C11.4584 15.5252 11.3345 15.6083 11.198 15.665C11.0615 15.7216 10.9151 15.7505 10.7673 15.75H3.125C2.82663 15.75 2.54048 15.6315 2.3295 15.4205C2.11853 15.2095 2 14.9234 2 14.625V3.375C2 3.07663 2.11853 2.79048 2.3295 2.5795C2.54048 2.36853 2.82663 2.25 3.125 2.25H14.375C14.6734 2.25 14.9595 2.36853 15.1705 2.5795C15.3815 2.79048 15.5 3.07663 15.5 3.375ZM3.125 14.625H10.4375V11.25C10.4375 11.1008 10.4968 10.9577 10.6023 10.8523C10.7077 10.7468 10.8508 10.6875 11 10.6875H14.375V3.375H3.125V14.625ZM11.5625 11.8125V13.8305L13.5798 11.8125H11.5625Z"
          fill="black"
        />
      </svg>
    </SVGContainer>
  );
}

export default SOAPIcon;
