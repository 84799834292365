import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
}

function BellIcon({
  width = 27,
  height = 27,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 29 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5625 25.1111H28L25.6292 22.6915C24.9862 22.0353 24.625 21.1453 24.625 20.2173V14.7778C24.625 10.2786 21.8075 6.45099 17.875 5.03244V4.44444C17.875 2.54213 16.364 1 14.5 1C12.636 1 11.125 2.54213 11.125 4.44444V5.03244C7.19248 6.45099 4.375 10.2786 4.375 14.7778V20.2173C4.375 21.1453 4.01379 22.0353 3.37082 22.6915L1 25.1111H9.4375M19.5625 25.1111V26.8333C19.5625 29.6868 17.2959 32 14.5 32C11.7041 32 9.4375 29.6868 9.4375 26.8333V25.1111M19.5625 25.1111H9.4375"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SVGContainer>
  );
}

export default BellIcon;
