import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { isFulfilled, isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const rtkQueryMutationNotifier: Middleware =
  (_api: MiddlewareAPI) => (next) => (action: any) => {
    const MUTATION_REJECTED = "api/executeMutation/rejected";
    const MUTATION_FULFILLED = "api/executeMutation/fulfilled";

    if (isFulfilled(action) && action?.type === MUTATION_FULFILLED) {
      let message = action.payload?.message || "Successfully done!";
      if (message.toLowerCase() !== 'success' && message.toLowerCase() !== 'ok') {
        toast(message, {
          type: "success",
        });
      }

    }

    if (isRejectedWithValue(action) && action?.type === MUTATION_REJECTED) {
      let messageData = action.payload?.data?.message || "Some error occurred!";
      if (Array.isArray(messageData)) {
        messageData.forEach((message) => {
          toast(message, {
            type: "error",
          });
        });
      } else {
        toast(messageData, {
          type: "error",
        });
      }
    }

    return next(action);
  };
