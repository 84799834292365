import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { CSSProperties } from "react";

declare module '@mui/material/styles' {
  interface Components {
    MuiMultiSectionDigitalClock?: {
      styleOverrides?: {
        root?: CSSProperties & {
          ul?: CSSProperties;
        };
      };
    };
  }
  interface Theme {
    colors: any;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors: any;
  }
}


const commonColor = {
  palatte: {
    primary: "#FF9F33",
    secondary: "#ECEDEE",
    success: "#0BF075",
    error: "#FF1E1E",
    warning: "#F9B959"
  },
};

const dark = {
  ...commonColor,
  color: {
    color1: "#FFFFFF",
    color2: "#D9D9D9",
    color3: "#F8FAFC",
    color4: "#F7F8FE"
  },

  invertColor: {
    color1: '#000000',
    color2: "#282726",
    color3: "#2F2D2D"
  }
};

const light = {
  ...commonColor,

  color: {
    color1: "#FFFFFF",
    color2: "#D9D9D9",
    color3: "#F8FAFC",
    color4: "#F7F8FE"
  },

  invertColor: {
    color1: '#000000',
    color2: "#282726",
    color3: "#2F2D2D"
  }
};

const createPrimaryTheme = (type = "light") => {
  const themeColors = type === "light" ? { ...light } : { ...dark };
  let theme = createTheme({
    colors: { ...themeColors },
    palette: {
      primary: {
        main: themeColors.palatte.primary,
      },
      secondary: {
        main: themeColors.palatte.secondary,
      },
      error: {
        main: themeColors.palatte.error,
      },
      success: {
        main: themeColors.palatte.primary,
      },
      warning: {
        main: themeColors.palatte.warning,
      },
      background: {
        paper: themeColors.color.color1,
      },
      text: {
        primary: themeColors.invertColor.color1,
        secondary: themeColors.invertColor.color1,
      },
    },
    typography: {
      fontFamily: `'Lato', sans-serif`,
      h1: {
        fontSize: "2rem",
        fontWeight: "600",
      },
      h2: {
        fontSize: "1.8rem",
        fontWeight: "600",
      },
      h3: {
        fontSize: "1.6rem",
        fontWeight: "600",
      },
      h4: {
        fontSize: "1.4rem",
        fontWeight: "600",
      },
      h5: {
        fontSize: "1.2rem",
        fontWeight: "600",
      },
      h6: {
        fontSize: "1rem",
        fontWeight: "600",
      },
    },
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            "&&&": {
              borderRadius: "0.44rem",
            },
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            "&&&": {
              borderRadius: "0.44rem",
            },
          },
        },
      },

      MuiFormGroup: {
        styleOverrides: {
          root: {
            "&&&": {},
          },
        },
      },

      MuiLink: {
        defaultProps: {
          underline: "none",
        },
      },

      MuiCard: {
        styleOverrides: {
          root: {
            "&&&": {
              borderRadius: "0.8rem",
              boxShadow: "none",
              border: `1px solid ${themeColors.color.color2}`,
            },
          },
        },
      },

      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:last-child td": {
              borderBottom: "none",
            },
            "&:nth-of-type(odd)": {
              backgroundColor: themeColors.color.color4,
            },
          },
          head: {
            th: {
              whiteSpace: "nowrap",
              backgroundColor: themeColors.color.color1,

              h6: {
                fontSize: '0.875rem',
                fontWeight: '400',
                color: 'black'
              }
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            whiteSpace: "pre-line", // Allow line breaks in the text content
          },
        },
      },

      MuiIconButton: {
        styleOverrides: {
          root: {
            "&&&": {
              padding: 0,
            },
          },
        },
      },

      MuiTextField: {
        defaultProps: {
          fullWidth: true,
          color: "secondary",
        },

        styleOverrides: {
          root: {
            "&&&": {
              backgroundColor: themeColors.color.color4,
              // borderRadius: '0.62rem',
              // '.Mui-focused .MuiOutlinedInput-notchedOutline': {
              //     boxShadow: `0 0 0.31rem rgba(33, 133, 202, 0.05)`
              // },

              "&.h-sm": {
                input: {
                  height: "2.80rem",
                  padding: "0 0.9rem",
                },
              },
            },
          },
        },
      },

      MuiPickersToolbar: {
        styleOverrides: {
          root: {
            "&&&": {
              ".MuiPickersToolbarText-root": {
                color: themeColors.invertColor.color1,
              },
              ".MuiTimePickerToolbar-ampmSelection": {
                ".Mui-selected": {
                  color: themeColors.palatte.primary,
                },
              },
            },
          },
        },
      },

      // MuiChip: {
      //   styleOverrides: {
      //     root: {
      //       "&:hover": {
      //         backgroundColor: 'rgba(11, 240, 117, 0.52)'
      //       },
      //     },
      //   },
      // },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            "&&&": {
              alignItems: 'flex-start',

              '.MuiCheckbox-root + .MuiTypography-root': {
                marginTop: '8px'
              }
            }
          }
        }
      },
      MuiMultiSectionDigitalClock: {
        styleOverrides: {
          root: {
            ul: {
               /* @ts-ignore */
              "&::-webkit-scrollbar": {
                width: "1px",
              } as CSSProperties,
            }
          },
        },
      },

      MuiButton: {
        defaultProps: {
          variant: "contained",
        },

        styleOverrides: {
          root: {
            "&&&": {
              fontSize: "1rem",
              boxShadow: "unset",
              textTransform: "capitalize",

              "*": {
                color: "inherit",
              },
            },
          },

          contained: {
            "&&&": {
              "&.unround": {
                borderRadius: "0.5rem",
              },
              head: {
                th: {
                  whiteSpace: "nowrap",
                },
              }
            },
          },
          containedPrimary: {
            "&&&": {
              backgroundColor: themeColors.palatte.primary,
              color: themeColors.invertColor.color1,
              borderRadius: "0.37rem",
            },
            "&&&:disabled": {
              backgroundColor: "rgba(0, 0, 0, 0.12)",
              color: "rgba(0, 0, 0, 0.26)",
            },
          },

          outlined: {
            "&&": {
              borderWidth: "0.12rem",
              borderRadius: "0.63rem",
              borderColor: "#7B7B87",
              color: themeColors.invertColor.color3,
            },
            "&&:disabled": {
              borderColor: "rgba(0, 0, 0, 0.12)",
              color: "rgba(0, 0, 0, 0.26)",
            },
          },

          fullWidth: {
            "&&&": {
              height: "3.45rem",
            },
          },

          text: {
            "&&&": {
              padding: 0,
              backgroundColor: "transparent",
              minWidth: "unset",
              borderRadius: 0,

              ".MuiTouchRipple-root": {
                display: "none",
              },

              "&:hover": {
                filter: "saturate(1.8)",
              },
            },
          },

          sizeLarge: {
            "&&&": {
              height: "3.45rem",
            },
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200
      },
    },
  });

  theme = responsiveFontSizes(theme);
  return theme;
};

export default createPrimaryTheme;
