import { RootState, store } from '../../Redux';
import { createApi, fetchBaseQuery, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import Configs from "src/Config";
import { logOut } from '../features/common/auth/authSlice';


const BASE_URL = Configs.PUBLIC_API_HOST;

const baseQuery = fetchBaseQuery({
   baseUrl: BASE_URL,
   prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      const isAuth = (getState() as RootState).auth.isAuthenticated;

      if (token) {
         headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
   },
   validateStatus: (response, result) => {

      if (response.status === 401) {
         console.log('Unauthorized. Logging out...');
      }
      if (response.status === 403) {
         console.log("You don't have permission to access this resource. Logging out...");
      }
      return response.status === 200 && !result.isError;
   }

});

const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
   args, 
   api, 
   extraOptions
) => {
   let result = await baseQuery(args, api, extraOptions);

   if (result.error && (result.error.status === 401 || result.error.status === 403)) {
      console.log('Unauthorized or Forbidden. Logging out...');
      api.dispatch(logOut()); // Dispatch logOut from api.dispatch
   }

   return result;
};

export const baseApi = createApi({
   baseQuery: baseQueryWithReAuth,
   keepUnusedDataFor: 0,
   endpoints: () => ({}),

});
