import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
  className?: string;
}

function SetCalenderIcon({
  width = 21,
  height = 21,
  iconColor,
  className,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || '#0D0D0D';
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        color={color}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M5.64331 2.47046V1M14.9319 2.47046V1"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M19.1127 7.37207H9.1275M1 7.37207H4.59932"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M12.1463 20.116H8.43086C4.92814 20.116 3.17631 20.116 2.08862 18.9671C1 17.8192 1 15.9703 1 12.2736V10.3129C1 6.61619 1 4.76732 2.08862 3.61938C3.17631 2.47046 4.92814 2.47046 8.43086 2.47046H12.1463C15.649 2.47046 17.4008 2.47046 18.4885 3.61938C19.5771 4.76732 19.5771 6.61619 19.5771 10.3129V12.2736C19.5771 15.9703 19.5771 17.8192 18.4885 18.9671C17.882 19.6082 17.0692 19.8915 15.8617 20.016"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </SVGContainer>
  );
}

export default SetCalenderIcon;
