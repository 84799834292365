import React from 'react';
import { Link } from 'react-router-dom';
import MatLink, { LinkProps as MatLinkProps } from '@mui/material/Link';

interface MuiLinkProps extends MatLinkProps {
  to: string;
}

const MuiLink: React.FC<MuiLinkProps> = ({ to, children, ...rest }) => {
  return (
    <MatLink component={Link} to={to} {...rest}>
      {children}
    </MatLink>
  );
};

export default MuiLink;