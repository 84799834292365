import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
  className?:string;
}

function CameraIcon({
  width = 38,
  height = 38,
  iconColor,
  className,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38ZM23.75 13.5193H26.7407C27.7127 13.5193 28.5 14.298 28.5 15.2593V25.1769C28.5 26.1382 27.7127 26.9168 26.7407 26.9168H11.2593C10.2873 26.9168 9.5 26.1382 9.5 25.1769V15.2593C9.5 14.298 10.2873 13.5193 11.2593 13.5193H14.25L14.9647 11.5445C15.0637 11.2683 15.3297 11.0834 15.6266 11.0834H22.3734C22.5186 11.0833 22.6603 11.1275 22.7792 11.2101C22.8981 11.2926 22.9883 11.4094 23.0375 11.5445L23.75 13.5193ZM15.4815 19.9571C15.4815 21.8797 17.056 23.4369 19 23.4369C20.944 23.4369 22.5185 21.8797 22.5185 19.9571C22.5185 18.0345 20.944 16.4772 19 16.4772C17.056 16.4772 15.4815 18.0345 15.4815 19.9571ZM17.5072 21.4334C17.1113 21.0419 16.8889 20.5108 16.8889 19.9571C16.8889 19.4033 17.1113 18.8723 17.5072 18.4807C17.9031 18.0891 18.4401 17.8692 19 17.8692C19.5599 17.8692 20.0969 18.0891 20.4928 18.4807C20.8887 18.8723 21.1111 19.4033 21.1111 19.9571C21.1111 20.5108 20.8887 21.0419 20.4928 21.4334C20.0969 21.825 19.5599 22.045 19 22.045C18.4401 22.045 17.9031 21.825 17.5072 21.4334Z"
          fill="#8D8D8D"
        />
      </svg>
    </SVGContainer>
  );
}

export default CameraIcon;
