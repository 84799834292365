import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
  className?:string;
}

function NotebookIcon({
  width = 17,
  height = 18,
  iconColor,
  className,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || "#FFFFFF";
  return (
    <SVGContainer {...restProps} className={className}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.12732 4.02269C4.12732 3.80931 4.21208 3.60467 4.36297 3.45379C4.51385 3.3029 4.71849 3.21814 4.93187 3.21814H12.9773C13.1907 3.21814 13.3954 3.3029 13.5462 3.45379C13.6971 3.60467 13.7819 3.80931 13.7819 4.02269C13.7819 4.23607 13.6971 4.44071 13.5462 4.59159C13.3954 4.74247 13.1907 4.82723 12.9773 4.82723H4.93187C4.71849 4.82723 4.51385 4.74247 4.36297 4.59159C4.21208 4.44071 4.12732 4.23607 4.12732 4.02269ZM4.12732 7.24087C4.12732 7.0275 4.21208 6.82286 4.36297 6.67197C4.51385 6.52109 4.71849 6.43633 4.93187 6.43633H12.9773C13.1907 6.43633 13.3954 6.52109 13.5462 6.67197C13.6971 6.82286 13.7819 7.0275 13.7819 7.24087C13.7819 7.45425 13.6971 7.65889 13.5462 7.80978C13.3954 7.96066 13.1907 8.04542 12.9773 8.04542H4.93187C4.71849 8.04542 4.51385 7.96066 4.36297 7.80978C4.21208 7.65889 4.12732 7.45425 4.12732 7.24087ZM4.93187 9.65452C4.71849 9.65452 4.51385 9.73928 4.36297 9.89016C4.21208 10.041 4.12732 10.2457 4.12732 10.4591C4.12732 10.6724 4.21208 10.8771 4.36297 11.028C4.51385 11.1788 4.71849 11.2636 4.93187 11.2636H12.9773C13.1907 11.2636 13.3954 11.1788 13.5462 11.028C13.6971 10.8771 13.7819 10.6724 13.7819 10.4591C13.7819 10.2457 13.6971 10.041 13.5462 9.89016C13.3954 9.73928 13.1907 9.65452 12.9773 9.65452H4.93187ZM4.12732 13.6773C4.12732 13.4639 4.21208 13.2592 4.36297 13.1083C4.51385 12.9575 4.71849 12.8727 4.93187 12.8727H8.15005C8.36343 12.8727 8.56807 12.9575 8.71896 13.1083C8.86984 13.2592 8.9546 13.4639 8.9546 13.6773C8.9546 13.8906 8.86984 14.0953 8.71896 14.2462C8.56807 14.397 8.36343 14.4818 8.15005 14.4818H4.93187C4.71849 14.4818 4.51385 14.397 4.36297 14.2462C4.21208 14.0953 4.12732 13.8906 4.12732 13.6773Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.909058 2.41364C0.909058 1.7735 1.16335 1.15958 1.616 0.706939C2.06864 0.254294 2.68256 0 3.3227 0H14.5864C15.2265 0 15.8404 0.254294 16.2931 0.706939C16.7457 1.15958 17 1.7735 17 2.41364V15.2864C17 15.9265 16.7457 16.5405 16.2931 16.9931C15.8404 17.4457 15.2265 17.7 14.5864 17.7H3.3227C2.68256 17.7 2.06864 17.4457 1.616 16.9931C1.16335 16.5405 0.909058 15.9265 0.909058 15.2864V2.41364ZM3.3227 1.60909H14.5864C14.7997 1.60909 15.0044 1.69386 15.1553 1.84474C15.3061 1.99562 15.3909 2.20026 15.3909 2.41364V15.2864C15.3909 15.4998 15.3061 15.7044 15.1553 15.8553C15.0044 16.0062 14.7997 16.0909 14.5864 16.0909H3.3227C3.10932 16.0909 2.90468 16.0062 2.7538 15.8553C2.60292 15.7044 2.51815 15.4998 2.51815 15.2864V2.41364C2.51815 2.20026 2.60292 1.99562 2.7538 1.84474C2.90468 1.69386 3.10932 1.60909 3.3227 1.60909Z"
          fill={color}
        />
      </svg>
    </SVGContainer>
  );
}

export default NotebookIcon;
